<template>
  <base-layout></base-layout>
</template>
<script>
import { toastController } from "@ionic/vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  async created() {
    const result = await this.destroyToken();
    console.log(result);
    if (result) {
      await this.$router.replace({ name: "login" });
      await this.openToast();
    }
  },
  methods: {
    ...mapActions("auth", ["destroyToken"]),
    async openToast() {
      const toast = await toastController.create({
        message: "Has salido correctamente del sistema.",
        position: "middle",
        color: "light",
        duration: 2000,
      });
      return toast.present();
    },
  },
};
</script>